import { useTranslation } from "react-i18next";
import { Skeleton, Spin } from "antd";

export const SkeletonSpinLoading = (props) => {
  // Translation function
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Skeleton active={props.active} />
      <Spin className='mt-2' tip={t("loading.ready")} size='large' />
    </div>
  );
};

export default SkeletonSpinLoading;
