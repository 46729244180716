import fetch from "./ServiceInitializer";

const UserService = {};

UserService.getUserPk = function () {
  return fetch({
    url: `/users/get_user_pk/`,
    method: "get",
  });
};

UserService.getUserInfo = function () {
  return fetch({
    url: `/users/get_user_info/`,
    method: "get",
  });
};

UserService.getPreferences = function (params) {
  return fetch({
    url: `/users/get_preferences`,
    method: "get",
    params: params,
  });
};

UserService.updatePreferences = function (data) {
  return fetch({
    url: `/users/update_preferences/`,
    method: "put",
    data: data,
  });
};

UserService.updateMeasurementUnits = function (data) {
  return fetch({
    url: `/measurement-units/update_measurement_units/`,
    method: "put",
    data: data,
  });
};

UserService.changeUserPassword = function (pk, data) {
  return fetch({
    url: `/change/password/${pk}/`,
    method: "put",
    data: data,
  });
};

UserService.submitContactForm = function (email) {
  return fetch({
    url: `/contact/`,
    method: "post",
    data: email,
  });
};

export default UserService;
