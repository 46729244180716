import axios from "axios";
import { API_AUTH_URL } from "configs/AppConfig";
import { requestInterceptor, responseInterceptor } from "auth/FetchInterceptor";

const service = axios.create({
  baseURL: API_AUTH_URL,
  timeout: 60000,
});

requestInterceptor(service);
responseInterceptor(service);

export default service;
