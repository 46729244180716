import fetch from "./ServiceInitializer";
import refreshFetch from "auth/RefreshFetchInterceptor";
import { REFRESH_TOKEN } from "constants/AuthConstant";

const AuthService = {};

AuthService.login = function (data) {
  return fetch({
    url: "/token/",
    method: "post",
    data: data,
  });
};

AuthService.refreshToken = function () {
  return refreshFetch({
    url: "/token/refresh/",
    method: "post",
    data: {
      refresh: localStorage.getItem(REFRESH_TOKEN),
    },
  });
};

export default AuthService;
