export const ROW_GUTTER = 16;
export const SIDE_NAV_WIDTH = 250;
export const SIDE_NAV_COLLAPSED_WIDTH = 80;
export const HEADER_HEIGHT = 70;
export const FOOTER_HEIGHT = 60;
export const LOCALE = "LOCALE";
export const THEME = "THEME";

export const DIR_LTR = "ltr";
export const DIR_RTL = "rtl";
export const CONTENT_MAX_WIDTH = 1400;
export const CONTENT_HEIGHT_OFFSET = HEADER_HEIGHT + FOOTER_HEIGHT;
export const LAYOUT_CONTENT_GUTTER = 25;
export const LAYOUT_CONTENT_GUTTER_SM = 15;

export const FONT_SIZE_BASE = 14;

export const TEMPLATE = {
  HEADER_HEIGHT,
  FOOTER_HEIGHT,
  CONTENT_HEIGHT_OFFSET,
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  CONTENT_MAX_WIDTH,
  LAYOUT_CONTENT_GUTTER,
  LAYOUT_CONTENT_GUTTER_SM,
  DIR_LTR,
  DIR_RTL,
};

export const FONT_WEIGHT = {
  LIGHTER: "lighter",
  LIGHT: 300,
  NORMAL: 400,
  SEMIBOLD: 500,
  BOLD: "bold",
  BASE: 400,
  BOLDER: "bolder",
};

export const FONT_SIZES = {
  BASE: FONT_SIZE_BASE,
  LG: FONT_SIZE_BASE + 2,
  SM: 12,
};

export const SPACER = {
  0: "0",
  1: "0.25rem",
  2: "0.5rem",
  3: "1rem",
  4: "1.5rem",
  5: "3rem",
};

const BREAKPOINT = {
  xs: "480px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1400px",
  xxl: "1600px",
};

export const MEDIA_QUERIES = {
  DESKTOP: `(min-width: ${BREAKPOINT.xl})`,
  LAPTOP: `(max-width: ${BREAKPOINT.xxl})`,
  LAPTOP_ABOVE: `(min-width: ${BREAKPOINT.xxl})`,
  TABLET: `(max-width: ${BREAKPOINT.lg})`,
  MOBILE: `(max-width: ${BREAKPOINT.md})`,
};

export const THEME_COLORS = {
  // EXCLUSIVE FOR LIGHT THEME
  WHITE: "#f7f7f7", // Used for background of the layout
  BLACK: "#131619", // Used for text
  GRAY_DARK: "#abacad", // Used for background on every component (cards, modals, etc.)
  GRAY: "#e1e2e2",
  GRAY_LIGHT: "#ececec",

  // EXCLUSIVE FOR DARK THEME
  DARK: "#1e2124", // Used for background of the layout
  BLANC: "#f7f7f7", // Used for text
  GLOOM_DARK: "#292b2e", // Used for background on every component (cards, modals, etc.)
  GLOOM: "#3e4143",
  GLOOM_LIGHT: "#545658",

  // USER FEEDBACK
  SUCCESS: "#08B07A",
  WARNING: "#EB9729",
  ERROR: "#F20746",

  // MAIN COLORS
  MAIN_LIGHT: "#263768",
  MAIN: "#041851",
  MAIN_DARK: "#00288F",

  // FOCUS COLORS
  FOCUS_LIGHT: "#6D95FD",
  FOCUS: "#62B9BE",
  FOCUS_DARK: "#009CF1",

  //COLORS FOR CHARTS
  CHART_COLORS: ["#0067A1", "#009CF1", "#39B7DF", "#069A8E", "#56BFA5"],

  // ADDITIONAL COLORS (FOR CHARTS, TAGS, ETC.)
  ADDITIONAL_COLORS: [
    "#6D95FD",
    "#0067A1",
    "#56BFA5",
    "#7895CB",
    "#39B7DF",
    "#069A8E",
    "#A0BFE0",
    "#4A55A2",
    "#009CF1",
    "#7F7BC5",
    "#56A71D",
    "#EDAA25",
    "#E9715C",
    "#945092",
    "#B53F67",
  ],
};

//COLORS FOR SOME SPECIFIC VALUES
export const PERSONALIZED_COLORS = {
  ANNOTATION: THEME_COLORS.ADDITIONAL_COLORS[13],
  ANNOTATION_POINT: THEME_COLORS.ADDITIONAL_COLORS[14],
  STAGE: THEME_COLORS.ADDITIONAL_COLORS[2],
  RECIPE: THEME_COLORS.ADDITIONAL_COLORS[9],
  ASSET: THEME_COLORS.ADDITIONAL_COLORS[3],
  BATCH: THEME_COLORS.ADDITIONAL_COLORS[5],
  VALUE: THEME_COLORS.ADDITIONAL_COLORS[6],
  USER: THEME_COLORS.ADDITIONAL_COLORS[5],
  PROCESS: THEME_COLORS.ADDITIONAL_COLORS[10],
  SET_POINT: THEME_COLORS.ADDITIONAL_COLORS[7],
  INSIGHT_PRIORITY: {
    LOW: THEME_COLORS.ADDITIONAL_COLORS[4],
    MEDIUM: THEME_COLORS.ADDITIONAL_COLORS[2],
    HIGH: THEME_COLORS.ADDITIONAL_COLORS[14],
    URGENT: THEME_COLORS.ADDITIONAL_COLORS[13],
  },
  INSIGHT_STATUS: {
    OPEN: THEME_COLORS.ADDITIONAL_COLORS[12],
    ACKNOWLEDGED: THEME_COLORS.ADDITIONAL_COLORS[11],
    CLOSED: THEME_COLORS.ADDITIONAL_COLORS[10],
  },
  EMPLOYEE_DEPARTMENT: {
    PRODUCTION: THEME_COLORS.ADDITIONAL_COLORS[11],
    MANAGEMENT: THEME_COLORS.ADDITIONAL_COLORS[12],
    SHIFT_MONITORING: THEME_COLORS.ADDITIONAL_COLORS[13],
  },
};

export const LIGHT_THEME_COLORS = {
  BACKGROUND: THEME_COLORS.WHITE,
  BORDER: THEME_COLORS.BLACK,
  HEADER_NAV: THEME_COLORS.WHITE,
  HEADING_TEXT: THEME_COLORS.BLACK,
  LAYOUT: THEME_COLORS.GRAY_LIGHT,
  MENU_ITEM_SELECTED: THEME_COLORS.FOCUS_LIGHT,
  SELECT_ACTIVE: THEME_COLORS.GRAY,
  SELECT_HOVER: THEME_COLORS.GRAY_LIGHT,
  SIDE_NAV: THEME_COLORS.WHITE,
  TEXT: THEME_COLORS.BLACK,
};

export const DARK_THEME_COLORS = {
  BACKGROUND: THEME_COLORS.GLOOM_DARK,
  BORDER: THEME_COLORS.BLANC,
  HEADER_NAV: THEME_COLORS.GLOOM_DARK,
  HEADING_TEXT: THEME_COLORS.BLANC,
  LAYOUT: THEME_COLORS.DARK,
  MENU_ITEM_SELECTED: THEME_COLORS.FOCUS_DARK,
  SELECT_ACTIVE: THEME_COLORS.GLOOM_DARK,
  SELECT_HOVER: THEME_COLORS.GLOOM_LIGHT,
  SIDE_NAV: THEME_COLORS.GLOOM_DARK,
  TEXT: THEME_COLORS.BLANC,
  DROP_DOWN_SHADOW:
    "0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 58%), 0 9px 28px 8px rgb(0 0 0 / 15%)",
};
