import { createSlice } from "@reduxjs/toolkit";
import { THEME_CONFIG } from "configs/AppConfig";

export const initialState = THEME_CONFIG;

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleCollapsedNav: (state, action) => {
      state.navCollapsed = action.payload;
    },
    onLocaleChange: (state, action) => {
      state.locale = action.payload;
    },
    onMobileNavToggle: (state, action) => {
      state.mobileNav = action.payload;
    },
    onSwitchTheme: (state, action) => {
      state.currentTheme = action.payload;
    },
    onDirectionChange: (state, action) => {
      state.direction = action.payload;
    },
    onBlankLayout: (state, action) => {
      state.blankLayout = action.payload;
    },
    resetTheme: (state) => {
      // Clear all the theme settings
      state.navCollapsed = initialState.navCollapsed;
      state.locale = initialState.locale;
      state.headerNavColor = initialState.headerNavColor;
      state.mobileNav = initialState.mobileNav;
      state.currentTheme = initialState.currentTheme;
      state.direction = initialState.direction;
      state.blankLayout = initialState.blankLayout;
    },
  },
});

export const {
  toggleCollapsedNav,
  onLocaleChange,
  onMobileNavToggle,
  onSwitchTheme,
  onDirectionChange,
  onBlankLayout,
  resetTheme,
} = themeSlice.actions;

export default themeSlice.reducer;
