import React, { lazy, memo, Suspense, useEffect } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { useSelector } from "react-redux";
import { ConfigProvider } from "antd";
import i18n from "i18next";
import Loading from "components/shared-components/Loading";
import { lightTheme, darkTheme } from "configs/ThemeConfig";
import { resources } from "lang";
import Routes from "routes";
import useBodyClass from "utils/hooks/useBodyClass";

const AppLayout = lazy(() => import("./AppLayout"));
const AuthLayout = lazy(() => import("./AuthLayout"));

const Layouts = () => {
  const token = useSelector((state) => state.auth.access_token);
  const blankLayout = useSelector((state) => state.theme.blankLayout);
  const currentTheme = useSelector((state) => state.theme.currentTheme);
  const locale = useSelector((state) => state.theme.locale);
  const direction = useSelector((state) => state.theme.direction);

  const Layout = token && !blankLayout ? AppLayout : AuthLayout;
  const currentAppLocale = resources[locale];
  const themeConfig =
    currentTheme === "light" ? { ...lightTheme } : { ...darkTheme };

  useBodyClass(`dir-${direction}`);

  // Theme switcher
  const { switcher, themes } = useThemeSwitcher();

  // Listen for changes in the current theme.
  // When the theme changes, update the theme in the theme switcher.
  useEffect(() => {
    switcher({ theme: themes[currentTheme] });
  }, [currentTheme, switcher, themes]);

  // Listen for changes in the locale.
  // When the locale changes, update the i18n instance.
  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]);

  return (
    <ConfigProvider
      theme={themeConfig}
      direction={direction}
      locale={currentAppLocale.antd}
    >
      <Suspense fallback={<Loading cover='content' />}>
        <Layout>
          <Routes />
        </Layout>
      </Suspense>
    </ConfigProvider>
  );
};

export default memo(Layouts);
