import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  assets: [],
};

const websocketSlice = createSlice({
  name: "assetOverview",
  initialState,
  reducers: {
    updateAssetList: (state, action) => {
      state.assets = action.payload;
    },
  },
});

export const { updateAssetList } = websocketSlice.actions;

export default websocketSlice.reducer;
