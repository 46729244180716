import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { WS_ENDPOINT_URL } from "configs/AppConfig";
import { ACCESS_TOKEN } from "constants/AuthConstant";

const initialState = {
  instance: null,
  callbacks: {},
  connected: false,
  error: null,
};

// Thunk to connect to WebSocket
export const connectWebSocket = createAsyncThunk(
  "websocket/connect",
  async (_, { rejectWithValue }) => {
    try {
      const path = `${WS_ENDPOINT_URL}websocket/live-update/?source=front-end&token=${localStorage.getItem(
        ACCESS_TOKEN
      )}`;
      const socket = new WebSocket(path, []);

      return new Promise((resolve, reject) => {
        socket.onopen = () => resolve(socket);
        socket.onerror = (error) => reject(error);
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Thunk to disconnect from WebSocket
export const disconnectWebSocket = createAsyncThunk(
  "websocket/disconnect",
  async (socket, { rejectWithValue }) => {
    try {
      socket.close();
      return true;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const websocketSlice = createSlice({
  name: "websocket",
  initialState,
  reducers: {
    addNewCallback: (state, action) => {
      const { command, callback } = action.payload;
      state.callbacks[command] = callback;
    },
    removeCallback: (state, action) => {
      const { commandName } = action.payload;
      delete state.callbacks[commandName];
    },
    newMessage: (state, action) => {
      const { command, data } = action.payload;
      const callback = state.callbacks[command];
      if (callback) {
        callback(data);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(connectWebSocket.fulfilled, (state, action) => {
        state.instance = action.payload;
        state.connected = true;
        state.error = null;
      })
      .addCase(connectWebSocket.rejected, (state, action) => {
        state.instance = null;
        state.connected = false;
        state.error = action.payload;
      })
      .addCase(disconnectWebSocket.fulfilled, (state) => {
        state.instance = null;
        state.connected = false;
        state.callbacks = {};
        state.error = null;
      })
      .addCase(disconnectWebSocket.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const { addNewCallback, removeCallback, newMessage } =
  websocketSlice.actions;

export default websocketSlice.reducer;
