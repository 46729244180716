import {
  THEME_COLORS,
  DARK_THEME_COLORS,
  LIGHT_THEME_COLORS,
} from "constants/ThemeConstant";

export function rgba(hex, opacity = 1) {
  if (!/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    throw new Error("Invalid Hex");
  }

  const decimal = parseInt(hex.substring(1), 16);
  const r = (decimal >> 16) & 255;
  const g = (decimal >> 8) & 255;
  const b = decimal & 255;

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

const getColorPrimary = (themeType) => {
  const themeColors =
    themeType === "dark" ? THEME_COLORS.FOCUS_DARK : THEME_COLORS.FOCUS_LIGHT;
  return themeColors;
};

export const baseTheme = {
  borderRadius: 10,
  colorPrimary: getColorPrimary("light"), // Default to light theme
  colorSuccess: THEME_COLORS.SUCCESS,
  colorWarning: THEME_COLORS.WARNING,
  colorError: THEME_COLORS.ERROR,
  controlHeight: 40,
  controlHeightLG: 48,
  controlHeightSM: 36,
  fontFamily: `'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji'`,
  fontSizeHeading2: 22,
  fontSizeHeading4: 17,
};

const getBaseComponentThemeConfig = (
  themeType,
  {
    Button = {},
    Menu = {},
    Card = {},
    Table = {},
    Select = {},
    DatePicker = {},
    Layout = {},
    Input = {},
    Dropdown = {},
    Calendar = {},
    Message = {},
  } = {}
) => {
  const isDarkTheme = themeType === "dark";

  const themeColors = isDarkTheme ? DARK_THEME_COLORS : LIGHT_THEME_COLORS;

  return {
    Breadcrumb: {
      colorTextDescription: themeColors.TEXT,
      colorText: themeColors.TEXT,
      colorBgTextHover: "transparent",
    },
    Button: {
      ...Button,
      controlOutline: "transparent",
      colorBorder: themeColors.BORDER,
      colorText: themeColors.TEXT,
    },
    Calendar: {
      ...Calendar,
      controlItemBgActive: themeColors.SELECT_ACTIVE,
    },
    Card: {
      ...Card,
      paddingLG: 20,
      colorTextHeading: themeColors.TEXT,
    },
    Checkbox: {
      lineWidth: 2,
      borderRadiusSM: 4,
    },
    DatePicker: {
      ...DatePicker,
      controlHeight: 40,
      controlHeightSM: 26,
      borderRadiusSM: 6,
      lineWidthBold: 0,
      controlItemBgActive: themeColors.BACKGROUND,
      controlItemBgHover: themeColors.SELECT_HOVER,
      ...(isDarkTheme && {
        boxShadowSecondary: themeColors.DROP_DOWN_SHADOW,
        controlOutline: "transparent",
      }),
    },
    Dropdown: {
      ...Dropdown,
      controlPaddingHorizontal: 20,
      controlHeight: 37,
      borderRadiusLG: 10,
      paddingXXS: "4px 0px",
      ...(isDarkTheme && { boxShadowSecondary: themeColors.DROP_DOWN_SHADOW }),
    },
    Input: {
      ...Input,
      ...(isDarkTheme && { controlOutline: "transparent" }),
    },
    Layout: {
      ...Layout,
      colorBgBody: themeColors.LAYOUT,
      colorBgHeader: themeColors.LAYOUT,
    },
    Menu: {
      ...Menu,
      colorItemBg: "transparent",
      colorActiveBarHeight: 0,
      colorActiveBarWidth: isDarkTheme ? 0 : 3,
      colorItemText: themeColors.TEXT,
      colorItemTextHover: themeColors.MENU_ITEM_SELECTED,
      colorItemTextSelected: themeColors.MENU_ITEM_SELECTED,
      colorItemTextSelectedHorizontal: themeColors.MENU_ITEM_SELECTED,
      colorItemBgHover: "transparent",
      colorItemBgSelected: isDarkTheme
        ? "transparent"
        : rgba(baseTheme.colorPrimary, 0.15),
      radiusItem: 0,
      colorSubItemBg: "transparent",
      itemMarginInline: 0,
      controlHeightLG: 40,
      controlHeightSM: 22,
      boxShadowSecondary: isDarkTheme
        ? themeColors.DROP_DOWN_SHADOW
        : undefined,
    },
    Pagination: {
      paginationItemSize: 30,
      borderRadius: "50%",
      colorBgContainer: themeColors.SELECT_ACTIVE,
      colorBgTextHover: isDarkTheme
        ? THEME_COLORS.GLOOM_LIGHT
        : THEME_COLORS.GRAY,
      colorPrimary: themeColors.BACKGROUND,
      colorPrimaryHover: isDarkTheme ? THEME_COLORS.WHITE : THEME_COLORS.BLACK,
    },
    Panel: {
      ...(isDarkTheme && { colorBgBody: "#ffffff" }),
    },
    Steps: {
      wireframe: true,
      controlHeight: 32,
    },
    Radio: {
      fontSizeLG: 18,
    },
    Slider: {
      colorPrimaryBorder: rgba(baseTheme.colorPrimary, 0.8),
      colorPrimaryBorderHover: baseTheme.colorPrimary,
    },
    Select: {
      paddingXXS: "4px 0px",
      controlOutline: "transparent",
      controlItemBgHover: themeColors.SELECT_HOVER,
      controlItemBgActive: themeColors.SELECT_ACTIVE,
      controlHeight: 40,
      controlHeightSM: 30,
      ...Select,
      ...(isDarkTheme && {
        boxShadowSecondary: themeColors.DROP_DOWN_SHADOW,
      }),
    },
    TreeSelect: {
      controlHeightSM: 24,
    },
    Tooltip: {
      colorBgDefault: rgba(themeColors.MENU_ITEM_SELECTED, 0.75),
      controlHeight: 32,
    },
    Timeline: {
      lineType: "dashed",
    },
    Tag: {
      lineHeight: 2.1,
    },
    Table: {
      colorText: themeColors.TEXT,
      colorTextHeading: themeColors.TEXT,
      colorFillAlter: themeColors.LAYOUT,
      tableHeaderBg: "transparent",
      ...Table,
    },
    // Other component configurations...
    Message: {
      // Error, warning, and success colors based on theme
      colorError: themeColors.ERROR,
      colorWarning: themeColors.WARNING,
      colorSuccess: themeColors.SUCCESS,
      ...Message, // Spread operator to allow additional overrides
    },
  };
};

export const lightTheme = {
  token: {
    ...baseTheme,
    colorPrimary: getColorPrimary("light"), // Set primary color for light theme
    colorBgBase: LIGHT_THEME_COLORS.BACKGROUND,
    colorBorder: LIGHT_THEME_COLORS.BORDER,
    colorText: LIGHT_THEME_COLORS.TEXT,
    colorTextBase: LIGHT_THEME_COLORS.TEXT,
  },
  components: getBaseComponentThemeConfig("light"),
};

export const darkTheme = {
  token: {
    ...baseTheme,
    colorPrimary: getColorPrimary("dark"), // Set primary color for dark theme
    colorBgBase: DARK_THEME_COLORS.BACKGROUND,
    colorBorder: DARK_THEME_COLORS.BORDER,
    colorText: DARK_THEME_COLORS.TEXT,
    colorTextBase: DARK_THEME_COLORS.TEXT,
  },
  components: getBaseComponentThemeConfig("dark"),
};
