import { all, fork } from "redux-saga/effects";
import websocketSagas from "./websocketSagas";

let isLiveUpdateEnabled;

if ("LIVE_UPDATE_ENABLED" in process.env) {
  isLiveUpdateEnabled =
    String(process.env.LIVE_UPDATE_ENABLED).toLowerCase() === "true";
} else {
  isLiveUpdateEnabled = false;
}

export default function* rootSaga() {
  // isLiveUpdateEnabled ? yield all([fork(websocketSagas)]) : yield all([]);
  yield all([]);
}
