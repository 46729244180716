import antdEnUS from "antd/es/locale/en_US";
import antdElGR from "antd/es/locale/el_GR";
import antdDeDE from "antd/es/locale/de_DE";
import de from "./locales/de_DE.json";
import en from "./locales/en_US.json";
import el from "./locales/el_GR.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { THEME_CONFIG } from "configs/AppConfig";

export const resources = {
  en: {
    translation: en,
    antd: antdEnUS,
  },
  el: {
    translation: el,
    antd: antdElGR,
  },
  de: {
    translation: de,
    antd: antdDeDE,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: THEME_CONFIG.locale,
  lng: THEME_CONFIG.locale,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
